<template>
   <div class="col-12 mb-1" ref="fatura">
      <div class="card mb-0" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="col-md text-center text-md-start cursor-pointer" @click="editar">
                  <div class="row align-items-center">
                     <div class="col-12">
                        <h1 class="font-17 mb-0 limitador-1 text-uppercase">R$ {{ parseFloat(fatura.total).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</h1>
                        <p class="font-10 mb-2 mb-sm-0 mb-md-2 limitador-1 text-secondary">
                           <span><i class="far fa-hashtag font-9 me-1"></i>{{ fatura.id }}</span>
                           <span class="text-green ms-3" v-if="fatura.pago"><i class="far fa-check font-9 me-1"></i>Pago</span>
                           <span class="text-yellow ms-3" v-else><i class="far fa-exclamation-triangle font-8 me-1"></i>Pendente</span>
                        </p>
                     </div>
                     <div class="col d-none d-md-block">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-calendar font-10 me-1" :class="fatura.pago ? 'text-green' : 'text-yellow'"></i><strong class="subtitle me-1 d-none d-lg-inline"> Período:</strong>
                           <span>{{ String(fatura.inicio.dayOfMonth).padStart(2, '0') +'/'+ String(fatura.inicio.monthValue).padStart(2, '0') +'/'+ fatura.inicio.year }} até</span>
                           <span> {{ String(fatura.fim.dayOfMonth).padStart(2, '0') +'/'+ String(fatura.fim.monthValue).padStart(2, '0') +'/'+ fatura.fim.year }}</span>
                        </p>
                     </div>
                     <div class="col-6 d-none d-xxl-block">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-info-circle font-10 me-1" :class="fatura.pago ? 'text-green' : 'text-yellow'"></i><strong class="subtitle me-1 d-none d-lg-inline"> Obs.:</strong>
                           <span> {{ fatura.observacao == null || String(fatura.observacao).trim().length == 0 ? 'Sem observação' : fatura.observacao }}</span>
                        </p>
                     </div>
                  </div>
               </div>
               <div class="wpx-150 font-15 text-center mx-auto mx-md-0">
                  <span>{{ String(fatura.vencimento.dayOfMonth).padStart(2, '0') +'/'+ String(fatura.vencimento.monthValue).padStart(2, '0') +'/'+ fatura.vencimento.year }}</span>
                  <small class="d-block font-12"><i class="far fa-calendar font-10 me-1" :class="fatura.pago ? 'text-green' : 'text-yellow'"></i> Data de vencimento</small>   
               </div>
               <div class="w-max-content font-16 text-center mx-auto mx-md-0 d-flex align-items-center" v-if="dadosUsuario.usuarioPermissoes.includes('Editar contas')">
                  <div class="btn-icone color-theme">
                     <span @click="editar"><i class="far fa-pencil"></i><small>Editar</small></span>
                  </div>
                  <div class="btn-icone text-red">
                     <span @click="remover"><i class="far fa-trash"></i><small>Excluir</small></span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
   name: 'Fatura',
   props: ['fatura', 'index'],
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         urlRest: state => state.urlRest
      })
   },
   methods: {
      editar : function () {
         if (this.dadosUsuario.usuarioPermissoes.includes('Editar contas')) {
            this.$emit('editar', this.fatura)
         }
      },
      remover : function () {
         Swal.fire({
            icon: 'warning',
            title: 'Excluir fatura?',
            text: 'A fatura será excluída, essa ação é irreversível.',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'configuracoes/deleteFatura',
                  params: {
                     id: this.fatura.id
                  }
               }).then(() => {
                  this.$refs.fatura.style.display = 'none'

                  this.$toast.fire({
                     icon: 'success',
                     title: 'Fatura excluída!'
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     this.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         });
      }
   }
}

</script>